import './style.scss';
import '@mantine/core/styles.css';
import {hydrateRoot} from 'react-dom/client';
import {default as Calendar} from './roots/Calendar/Calendar.jsx';
import {default as Directory} from './roots/Directory/Directory.jsx';
import {default as Manifest} from './roots/Manifest/Manifest.jsx';
import {default as Issues} from './roots/Issues/Issues.jsx';
import {default as StoryMap} from './roots/StoryMap/StoryMap.jsx';
import {default as Estimator} from './roots/Estimator/Estimator.jsx';
import {default as BusinessModel} from './roots/BusinessModel/BusinessModel.jsx';
import {unpack} from 'msgpackr';
import {Buffer} from 'buffer';
import React from 'react';

const COMPONENTS = {
  'calendar': Calendar,
  'directory': Directory,
  'manifest': Manifest,
  'issues': Issues,
  'storymap': StoryMap,
  'estimator': Estimator,
  'business-model': BusinessModel,
};

document.querySelector('.file-view.markup.mdx')?.classList?.replace('markup', 'wysiwyg');
document.querySelectorAll('.mdx2html').forEach((node) => {
  let props = unpack(Buffer.from(node.getAttribute('data-props'), 'base64'));
  let component = COMPONENTS[node.getAttribute('data-type')];
  ['data-type', 'data-props'].forEach((p) => node.removeAttribute(p));
  hydrateRoot(node, React.createElement(component, props, []));
});
