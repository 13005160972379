import React from 'react';
import {Group, rem, Text} from '@mantine/core';
import {
  IconLetterDSmall,
  IconLetterESmall,
  IconLetterISmall,
  IconLetterRSmall,
  IconLetterSSmall,
  IconLetterTSmall,
  IconLetterXSmall
} from '@tabler/icons-react';

const SearchTag = ({label}) => {
  return <Group gap={0} wrap={'nowrap'} style={{height: '100%'}}>
    <Text size={'xs'} style={{position: 'relative', top: '1px'}}>{label}</Text>
  </Group>;
};
SearchTag.displayName = '@mdx@html/SearchTag';
SearchTag.defaultProps = {kind: null, withRemove: false};

const SearchTagIs = ({label}) => {
  return <Group gap={0} wrap={'nowrap'} style={{height: '100%'}}>
    <IconLetterISmall
      size={16}
      color={'var(--mantine-color-pink-6)'}
      style={{marginLeft: rem('-0.3rem'), position: 'relative', bottom: '1px'}}/>
    <IconLetterSSmall
      size={16}
      color={'var(--mantine-color-pink-6)'}
      style={{marginLeft: rem('-0.8rem'), position: 'relative', bottom: '1px'}}/>
    <Text size={'xs'} style={{position: 'relative', top: '1px'}}>{label}</Text>
  </Group>;
};
SearchTagIs.displayName = '@mdx@html/SearchTagIs';
SearchTagIs.defaultProps = {kind: 'is', withRemove: false};

const SearchTagId = ({label}) => {
  return <Group gap={0} wrap={'nowrap'} style={{height: '100%'}}>
    <IconLetterISmall
      size={16}
      color={'var(--mantine-color-lime-6)'}
      style={{marginLeft: rem('-0.3rem'), position: 'relative', bottom: '1px'}}/>
    <IconLetterDSmall
      size={16}
      color={'var(--mantine-color-lime-6)'}
      style={{marginLeft: rem('-0.8rem'), position: 'relative', bottom: '1px'}}/>
    <Text size={'xs'} style={{position: 'relative', top: '1px'}}>{label}</Text>
  </Group>;
};
SearchTagId.displayName = '@mdx@html/SearchTagId';
SearchTagId.defaultProps = {kind: 'id', withRemove: false};

const SearchTagText = ({label}) => {
  return <Group gap={0} wrap={'nowrap'} style={{height: '100%'}}>
    <IconLetterTSmall
      size={16}
      color={'var(--mantine-color-indigo-6)'}
      style={{marginLeft: rem('-0.3rem'), position: 'relative', bottom: '1px'}}/>
    <IconLetterESmall
      size={16}
      color={'var(--mantine-color-indigo-6)'}
      style={{marginLeft: rem('-0.8rem'), position: 'relative', bottom: '1px'}}/>
    <IconLetterXSmall
      size={16}
      color={'var(--mantine-color-indigo-6)'}
      style={{marginLeft: rem('-0.8rem'), position: 'relative', bottom: '1px'}}/>
    <IconLetterTSmall
      size={16}
      color={'var(--mantine-color-indigo-6)'}
      style={{marginLeft: rem('-0.8rem'), position: 'relative', bottom: '1px'}}/>
    <Text size={'xs'} style={{position: 'relative', top: '1px'}}>{label}</Text>
  </Group>;
};
SearchTagText.displayName = '@mdx@html/SearchTagText';
SearchTagText.defaultProps = {kind: 'text', withRemove: false};

const SearchTagRelated = ({label}) => {
  return <Group gap={0} wrap={'nowrap'} style={{height: '100%'}}>
    <IconLetterRSmall
      size={16}
      color={'var(--mantine-color-teal-6)'}
      style={{marginLeft: rem('-0.3rem'), position: 'relative', bottom: '1px'}}/>
    <Text size={'xs'} style={{position: 'relative', top: '1px'}}>{label}</Text>
  </Group>;
};
SearchTagRelated.displayName = '@mdx@html/SearchTagRelated';
SearchTagRelated.defaultProps = {kind: 'related', withRemove: false};

SearchTag.Is = SearchTagIs;
SearchTag.Id = SearchTagId;
SearchTag.Text = SearchTagText;
SearchTag.Related = SearchTagRelated;
export default SearchTag;
