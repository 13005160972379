import React, {useState} from 'react';
import {
  AppShell,
  Badge,
  Card,
  Flex,
  Group,
  Highlight,
  HoverCard,
  MantineProvider,
  Paper,
  Pill,
  PillsInput,
  rem,
  Stack,
  Switch,
  Table,
  Text,
  ThemeIcon,
} from '@mantine/core';
import {
  IconAlertCircle,
  IconCheck,
  IconCircle,
  IconCircleCheck,
  IconCircleOff,
  IconMaximize,
  IconMinimize,
  IconPointFilled,
  IconProgress,
  IconSearch
} from '@tabler/icons-react';
import {pack, unpack} from 'msgpackr';
import {Buffer} from 'buffer';
import {compareVersions} from 'compare-versions';
import {Dropdown, SearchTag, StatusIcon} from '../../components';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(LocalizedFormat);

export const DEFAULT = pack([]).toString('base64');

export const PREV_VERSION = `${Number.MAX_SAFE_INTEGER - 1}.${Number.MAX_SAFE_INTEGER}.${Number.MAX_SAFE_INTEGER}`;

export const LAST_VERSION = `${Number.MAX_SAFE_INTEGER}.${Number.MAX_SAFE_INTEGER}.${Number.MAX_SAFE_INTEGER}`;

const StoryMap = ({data, minimized: initialMinimized = false}) => {
  const [search, setSearch] = useState('');
  const [pills, setPills] = useState([
    <SearchTag.Is key={'Opened'} id={'Opened'} label={'Opened'} withRemove/>,
    <SearchTag.Is key={'InProcess'} id={'InProcess'} label={'In Process'} withRemove/>
  ]);
  const [columns, setColumns] = useState(
    (data instanceof Map ? Array.from(data.entries()) : Object.entries(data))
      .sort(([lhs], [rhs]) => rhs.localeCompare(lhs))
      .map(([ckey, rows]) => [ckey, initialMinimized, rows])
      .rotateRight()
  );

  let related = [...new Map((data instanceof Map ? Array.from(data.entries()) : Object.entries(data))
    .flatMap(([, rows]) => (rows instanceof Map ? Array.from(rows.values()) : Object.values(rows))
      .flatMap((stack) => stack.flatMap((issue) => issue.related)))
    .filter((related) => !(['Release', 'Tag'].includes((new URL(related.type)).hash.slice(1))))
    .map(r => [r.id, r])).values()];

  return (
    <MantineProvider defaultColorScheme={'dark'} withNormalizeCSS>
      <Paper radius={'md'} withBorder>
        <AppShell padding={0}>
          <AppShell.Header style={{position: 'relative', backgroundColor: 'transparent', zIndex: 'auto'}}>
            <Flex p={8}>
              <Group style={{flexGrow: 1}}>
                <PillsInput style={{flexGrow: 1}} leftSection={<IconSearch size={14}/>}>
                  <Pill.Group>
                    {pills.map((pill) => <Pill
                      key={`${pill.props.kind}:${pill.props.id}`}
                      onRemove={() => setPills((current) => {
                        return current.filter((v) => v.props.kind !== pill.props.kind || v.props.id !== pill.props.id);
                      })}
                      withRemoveButton={pill.props.withRemove}>{pill}</Pill>)}
                    <PillsInput.Field
                      // onFocus={() => combobox.openDropdown()}
                      // onBlur={() => combobox.closeDropdown()}
                      placeholder={'Search'}
                      value={search}
                      onChange={(event) => {
                        // combobox.updateSelectedOptionIndex();
                        setSearch(event.currentTarget.value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' && search.length !== 0) {
                          event.preventDefault();
                          setPills((current) => {
                            if (!current.find((v) => v.props.kind === 'text' && v.props.id === search)) {
                              return [...current, <SearchTag.Text key={search} id={search} label={search} withRemove/>];
                            } else return current;
                          });
                          setSearch('');
                        }
                        if (event.key === 'Backspace' && search.length === 0) {
                          event.preventDefault();
                          // handleValueRemove(value[value.length - 1]);
                        }
                      }}
                    />
                  </Pill.Group>
                </PillsInput>
              </Group>
              <Group align={'center'} style={{justifyContent: 'flex-end', marginLeft: rem('0.5rem')}}>
                <Switch
                  size={'xl'}
                  checked={columns.some(([, minimized]) => minimized)}
                  onChange={() => setColumns((columns) => {
                    let checked = columns.some(([, minimized]) => minimized);
                    return columns.map((col) => [col[0], !checked, col[2]]);
                  })}
                  color={'var(--mantine-color-dark-6)'}
                  onLabel={<IconMinimize stroke={3} size={18} style={{color: 'var(--mantine-color-green-6)'}}/>}
                  offLabel={<IconMaximize stroke={3} size={18} style={{color: 'var(--mantine-color-green-6)'}}/>}
                  thumbIcon={columns.some(([, minimized]) => minimized) && !columns.every(([, minimized]) => minimized) ?
                    <IconPointFilled stroke={3} size={18} style={{color: 'var(--mantine-color-dark-6)'}}/> : null}
                />
              </Group>
            </Flex>
            <Flex p={8}>
              <Group align={'center'} style={{flexGrow: 1, flexDirection: 'row-reverse'}}>
                <Dropdown width={180} label={'Status'}>
                  <Dropdown.Option
                    id={'Opened'}
                    label={'Opened'}
                    onClick={() => setPills((current) => {
                      if (!current.find((v) => v.props.kind === 'is' && v.props.id === 'Opened')) {
                        return [...current, <SearchTag.Is key={'Opened'} id={'Opened'} label={'Opened'} withRemove/>];
                      } else return current.filter((v) => v.props.kind !== 'is' || v.props.id !== 'Opened');
                    })}
                    leftSection={<IconCircle size={14} style={{color: 'var(--color-green-badge)'}}/>}
                    rightSection={pills.find((v) => v.props.kind === 'is' && v.props.id === 'Opened') ?
                      <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}/>
                  <Dropdown.Option
                    id={'Completed'}
                    label={'Completed'}
                    onClick={() => setPills((current) => {
                      if (!current.find((v) => v.props.kind === 'is' && v.props.id === 'Completed')) {
                        return [...current,
                          <SearchTag.Is key={'Completed'} id={'Completed'} label={'Completed'} withRemove/>];
                      } else return current.filter((v) => v.props.kind !== 'is' || v.props.id !== 'Completed');
                    })}
                    leftSection={<IconCircleCheck size={14} style={{color: 'var(--color-blue)'}}/>}
                    rightSection={pills.find((v) => v.props.kind === 'is' && v.props.id === 'Completed') ?
                      <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}/>
                  <Dropdown.Option
                    id={'Cancelled'}
                    label={'Cancelled'}
                    onClick={() => setPills((current) => {
                      if (!current.find((v) => v.props.kind === 'is' && v.props.id === 'Cancelled')) {
                        return [...current,
                          <SearchTag.Is key={'Cancelled'} id={'Cancelled'} label={'Cancelled'} withRemove/>];
                      } else return current.filter((v) => v.props.kind !== 'is' || v.props.id !== 'Cancelled');
                    })}
                    leftSection={<IconCircleOff size={14} style={{color: 'var(--mantine-color-dark-4)'}}/>}
                    rightSection={pills.find((v) => v.props.kind === 'is' && v.props.id === 'Cancelled') ?
                      <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}/>
                  <Dropdown.Option
                    id={'NeedsAction'}
                    label={'Needs Action'}
                    onClick={() => setPills((current) => {
                      if (!current.find((v) => v.props.kind === 'is' && v.props.id === 'NeedsAction')) {
                        return [...current,
                          <SearchTag.Is key={'NeedsAction'} id={'NeedsAction'} label={'Needs Action'} withRemove/>];
                      } else return current.filter((v) => v.props.kind !== 'is' || v.props.id !== 'NeedsAction');
                    })}
                    leftSection={<IconAlertCircle size={14} style={{color: 'var(--color-error-border)'}}/>}
                    rightSection={pills.find((v) => v.props.kind === 'is' && v.props.id === 'NeedsAction') ?
                      <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}/>
                  <Dropdown.Option
                    id={'InProcess'}
                    label={'In Process'}
                    onClick={() => setPills((current) => {
                      if (!current.find((v) => v.props.kind === 'is' && v.props.id === 'InProcess')) {
                        return [...current,
                          <SearchTag.Is key={'InProcess'} id={'InProcess'} label={'In Process'} withRemove/>];
                      } else return current.filter((v) => v.props.kind !== 'is' || v.props.id !== 'InProcess');
                    })}
                    leftSection={<IconProgress size={14} style={{color: 'var(--color-warning-text)'}}/>}
                    rightSection={pills.find((v) => v.props.kind === 'is' && v.props.id === 'InProcess') ?
                      <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}/>
                </Dropdown>
                <Dropdown width={180} label={'Related'}>
                  {related.map((r) => {
                    const label = (new URL(r.id)).hash.slice(1);
                    return <Dropdown.Option
                      key={r.id}
                      id={r.id}
                      label={label}
                      onClick={() => setPills((current) => {
                        if (!current.find((v) => v.props.kind === 'related' && v.props.id === r.id)) {
                          return [...current, <SearchTag.Related key={r.id} id={r.id} label={label} withRemove/>];
                        } else return current.filter((v) => v.props.kind !== 'related' || v.props.id !== r.id);
                      })}
                      rightSection={pills.find((v) => v.props.kind === 'related' && v.props.id === r.id) ?
                        <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}/>;
                  })}
                </Dropdown>
              </Group>
            </Flex>
          </AppShell.Header>
          <AppShell.Main style={{overflow: 'auto', minHeight: 'unset'}}>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  {columns.map(([ckey, minimized, rows], index) => {
                    let issuesPerColumnCount = (rows instanceof Map ? Array.from(rows.values()) : Object.values(rows))
                      .reduce((count, stack) => count + stack
                        .filter((issue) => pills.find((v) => v.props.kind === 'is' && v.props.id === (issue.status ?? 'Opened')))
                        .filter((issue) => {
                          if (pills.reduce((count, v) => count + (v.props.kind === 'text'), 0) > 0) {
                            return pills.some((v) => v.props.kind === 'text' && issue.label.includes(v.props.id)) || (search.length > 0 && issue.label.includes(search));
                          } else return true;
                        })
                        .filter((issue) => {
                          if (pills.reduce((count, v) => count + (v.props.kind === 'related'), 0) > 0) {
                            return pills.some((v) => v.props.kind === 'related' && issue.related.find((r) => r.id === v.props.id));
                          } else return true;
                        })
                        .reduce((count) => count + 1, 0), 0);

                    return issuesPerColumnCount > 0 ? <Table.Th
                      key={ckey}
                      style={{
                        verticalAlign: 'bottom',
                        ...(index ? {borderLeft: `${rem('0.0625rem')} solid var(--_table-border-color)`} : {})
                      }}>
                      <Flex align="flex-end">
                        <Switch
                          checked={minimized}
                          onChange={() => setColumns((columns) => {
                            return columns.map((col) => {
                              return col[0] === ckey ? [col[0], !col[1], col[2]] : col;
                            });
                          })}
                          color={'var(--mantine-color-dark-6)'}
                          style={{marginRight: 'var(--mantine-spacing-xs)'}}
                          onLabel={<IconMinimize size={12} style={{color: 'var(--mantine-color-green-6)'}}/>}
                          offLabel={<IconMaximize size={12} style={{color: 'var(--mantine-color-green-6)'}}/>}
                        />
                        <Group gap={'xs'}>
                          {unpack(Buffer.from(ckey, 'base64')).map((r) => {
                            return <Badge
                              key={r.id}
                              variant={'light'}
                              color={r.color ?? 'blue'}
                              size={'sm'}
                              radius={'sm'}>{r.label ?? (new URL(r.id)).hash.slice(1)}</Badge>;
                          })}
                        </Group>
                      </Flex>
                    </Table.Th> : null;
                  })}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{(data instanceof Map ? Array.from(data.get(DEFAULT).keys()) : Object.keys(data[DEFAULT]))
                .map((v) => [v, unpack(Buffer.from(v, 'base64'))])
                .sort(([, lhs], [, rhs]) => compareVersions(
                  lhs.map((obj) => obj.version ?? PREV_VERSION).sort(compareVersions)[0] ?? LAST_VERSION,
                  rhs.map((obj) => obj.version ?? PREV_VERSION).sort(compareVersions)[0] ?? LAST_VERSION
                ))
                .flatMap(([rkey, rlist]) => {
                  const issuesPerRowCount = columns.reduce((count, [, , rows]) => count + (rows instanceof Map ? rows.get(rkey) : rows[rkey])
                    .filter((issue) => pills.find((v) => v.props.kind === 'is' && v.props.id === (issue.status ?? 'Opened')))
                    .filter((issue) => {
                      if (pills.reduce((count, v) => count + (v.props.kind === 'text'), 0) > 0) {
                        return pills.some((v) => v.props.kind === 'text' && issue.label.includes(v.props.id)) || (search.length > 0 && issue.label.includes(search));
                      } else return true;
                    })
                    .filter((issue) => {
                      if (pills.reduce((count, v) => count + (v.props.kind === 'related'), 0) > 0) {
                        return pills.some((v) => v.props.kind === 'related' && issue.related.find((r) => r.id === v.props.id));
                      } else return true;
                    })
                    .reduce((count) => count + 1, 0), 0);

                  // console.log('rkey:', rkey);
                  // console.log('rlist:', rlist);
                  return issuesPerRowCount > 0 ? [
                    <Table.Tr key={`row-${rkey}`} style={{borderBottom: 'none'}}>
                      {columns.map(([ckey, minimized, rows]) => {
                        let issuesPerColumnCount = (rows instanceof Map ? Array.from(rows.values()) : Object.values(rows))
                          .reduce((count, stack) => count + stack
                            .filter((issue) => pills.find((v) => v.props.kind === 'is' && v.props.id === (issue.status ?? 'Opened')))
                            .filter((issue) => {
                              if (pills.reduce((count, v) => count + (v.props.kind === 'text'), 0) > 0) {
                                return pills.some((v) => v.props.kind === 'text' && issue.label.includes(v.props.id)) || (search.length > 0 && issue.label.includes(search));
                              } else return true;
                            })
                            .filter((issue) => {
                              if (pills.reduce((count, v) => count + (v.props.kind === 'related'), 0) > 0) {
                                return pills.some((v) => v.props.kind === 'related' && issue.related.find((r) => r.id === v.props.id));
                              } else return true;
                            })
                            .reduce((count) => count + 1, 0), 0);

                        const stack = (rows instanceof Map ? rows.get(rkey) : rows[rkey])
                          .sort((lhs, rhs) => priority(lhs.status) - priority(rhs.status) || new Date(rhs.createDate ?? null) - new Date(lhs.createDate ?? null))
                          .filter((issue) => pills.find((v) => v.props.kind === 'is' && v.props.id === (issue.status ?? 'Opened')))
                          .filter((issue) => {
                            if (pills.reduce((count, v) => count + (v.props.kind === 'text'), 0) > 0) {
                              return pills.some((v) => v.props.kind === 'text' && issue.label.includes(v.props.id)) || (search.length > 0 && issue.label.includes(search));
                            } else return true;
                          })
                          .filter((issue) => {
                            if (pills.reduce((count, v) => count + (v.props.kind === 'related'), 0) > 0) {
                              return pills.some((v) => v.props.kind === 'related' && issue.related.find((r) => r.id === v.props.id));
                            } else return true;
                          });
                        // console.log('ckey:', ckey);
                        // console.log('rows:', rows);
                        return issuesPerColumnCount > 0 ? <Table.Td key={ckey} style={{verticalAlign: 'top'}}>
                          {minimized ?
                            <Group w={130} maw={130} gap="xs">
                              {stack
                                .map((issue) => {
                                  return <HoverCard
                                    key={issue.id}
                                    width={280}
                                    shadow="md"
                                    radius="md"
                                    keepMounted={true}
                                    styles={{dropdown: {padding: 0}}}
                                    position="bottom-start">
                                    <HoverCard.Target>
                                      <Card
                                        padding={rem('0.5rem')}
                                        shadow="sm"
                                        radius="md"
                                        withBorder>
                                        <ThemeIcon
                                          size={'xs'}
                                          style={{backgroundColor: 'transparent', border: 'none'}}>
                                          <StatusIcon status={issue.status}/>
                                        </ThemeIcon>
                                      </Card>
                                    </HoverCard.Target>
                                    <HoverCard.Dropdown>
                                      <IssueToolbar
                                        highlight={[
                                          ...pills.filter((v) => v.props.kind === 'text').map((v) => v.props.id),
                                          search
                                        ]} {...issue}/>
                                      <Group
                                        gap="xs"
                                        px="var(--mantine-spacing-xs)"
                                        pb="var(--mantine-spacing-xs)"
                                        style={{flexDirection: 'row-reverse'}}>
                                        {issue.related
                                          .filter((r) => !(['Release', 'Tag'].includes((new URL(r.type)).hash.slice(1))))
                                          .map((r) => (
                                            <Badge
                                              key={(new URL(r.id)).hash.slice(1)}
                                              variant={'light'}
                                              color={r.color ?? 'blue'}
                                              size={'sm'}
                                              radius={'sm'}>{r.label ?? (new URL(r.id)).hash.slice(1)}</Badge>
                                          ))}
                                      </Group>
                                    </HoverCard.Dropdown>
                                  </HoverCard>;
                                })}
                            </Group> :
                            <Stack w={280} maw={280} gap={'xs'}>
                              {stack
                                .map((issue) => {
                                  return <Card
                                    key={issue.id}
                                    padding={rem('0.5rem')}
                                    shadow="sm"
                                    radius="md"
                                    withBorder>
                                    <Card.Section>
                                      <IssueToolbar
                                        highlight={[
                                          ...pills.filter((v) => v.props.kind === 'text').map((v) => v.props.id),
                                          search
                                        ]} {...issue}/>
                                    </Card.Section>
                                    <Card.Section>
                                      <Group
                                        gap="xs"
                                        px="var(--mantine-spacing-xs)"
                                        pb="var(--mantine-spacing-xs)"
                                        style={{flexDirection: 'row-reverse'}}>
                                        {issue.related
                                          .filter((r) => !(['Release', 'Tag'].includes((new URL(r.type)).hash.slice(1))))
                                          .map((r) => (
                                            <Badge
                                              key={(new URL(r.id)).hash.slice(1)}
                                              variant={'light'}
                                              color={r.color ?? 'blue'}
                                              size={'sm'}
                                              radius={'sm'}>{r.label ?? (new URL(r.id)).hash.slice(1)}</Badge>
                                          ))}
                                      </Group>
                                    </Card.Section>
                                  </Card>;
                                })}
                            </Stack>}
                        </Table.Td> : null;
                      })}
                    </Table.Tr>,
                    <Table.Tr key={`label-${rkey}`}>
                      <Table.Td colSpan={columns.length}>
                        <Flex justify={'flex-start'}>
                          {rlist.map((r) => {
                            return <Badge
                              key={r.id}
                              variant={'light'}
                              color={r.color ?? 'blue'}
                              css={{
                                position: 'sticky',
                                left: 'var(--table-horizontal-spacing, var(--mantine-spacing-xs))'
                              }}
                              size={'sm'}
                              radius={'sm'}>{r.label ?? (new URL(r.id)).hash.slice(1)}</Badge>;
                          })}
                        </Flex>
                      </Table.Td>
                    </Table.Tr>,
                  ] : null;
                })}</Table.Tbody>
            </Table>
          </AppShell.Main>
        </AppShell>
      </Paper>
    </MantineProvider>
  );
};

Array.prototype.rotateRight = function () {
  this.unshift(this.pop());
  return this;
};

const priority = (status) => {
  switch (status) {
  case 'Cancelled':
    return 5;
  case 'NeedsAction':
    return 1;
  case 'Completed':
    return 4;
  case 'InProcess':
    return 2;
  default:
    return 3;
  }
};

const IssueToolbar = ({label, status, highlight, createDate}) => {
  return <Flex p={'0.5rem'} direction={'row'} gap={'0.5rem'} justify="flex-start" align="flex-start">
    <Group>
      <ThemeIcon size={'xs'} style={{backgroundColor: 'transparent', border: 'none'}}>
        <StatusIcon status={status}/>
      </ThemeIcon>
    </Group>
    <Flex gap={0} justify={'flex-start'} align={'flex-start'} direction={'column'} style={{flexGrow: 1}}>
      <Highlight size={'0.9rem'} fw={500} highlight={highlight}>{label}</Highlight>
      <Text size={'0.8rem'} c={'dimmed'} mt={'0.3rem'}>{`Opened on ${dayjs(createDate).format('ll')}`}</Text>
    </Flex>
  </Flex>;
};

export default StoryMap;
