import React from 'react';
import {CacheProvider, css, Global} from '@emotion/react';
import createCache from '@emotion/cache';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import rrulePlugin from '@fullcalendar/rrule';

const Calendar = ({events}) => {
  const emotionCache = createCache({key: 'fullcalendar'});

  return (
    <CacheProvider value={emotionCache}>
      <Global
        styles={css`
          .fc-theme-standard .fc-scrollgrid {
            border-color: var(--color-secondary);
          }

          .fc-theme-standard td, .fc-theme-standard th {
            border-color: var(--color-secondary);
          }
        `}
      />
      <FullCalendar
        plugins={[rrulePlugin, dayGridPlugin, timeGridPlugin, multiMonthPlugin]}
        initialView="dayGridMonth"
        navLinks={true}
        weekNumbers={true}
        nowIndicator={true}
        weekNumberFormat={{week: 'numeric'}}
        dayMaxEvents={true}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay'
        }}
        events={events}
      />
    </CacheProvider>
  );
};

export default Calendar;
