import React, {useMemo, useState} from 'react';
import {
  AppShell,
  Card,
  Flex,
  Group,
  MantineProvider,
  Paper,
  Pill,
  PillsInput,
  rem,
  Stack,
  Table,
  Text,
  ThemeIcon,
  Title
} from '@mantine/core';
import {
  IconAlertTriangle,
  IconBuildingFactory2,
  IconBulb,
  IconCheck,
  IconCheckbox,
  IconCurrencyDollar,
  IconGift,
  IconHeartHandshake,
  IconLink,
  IconSearch,
  IconStar,
  IconTag,
  IconTruck,
  IconUsers
} from '@tabler/icons-react';
import {css} from '@emotion/react';
import {Dropdown, SearchTag} from '../../components';

const cellWrap = css`
  vertical-align: top;
  position: relative;
`;

const cellRoot = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 var(--table-horizontal-spacing, var(--mantine-spacing-xs)) var(--table-vertical-spacing) var(--table-horizontal-spacing, var(--mantine-spacing-xs));
  overflow-y: auto;
`;

const cellHeader = css`
  background-color: var(--mantine-color-body);
  position: sticky;
  top: 0;
  width: 100%;
  padding-top: var(--table-vertical-spacing);
  z-index: 1;
`;

const BusinessModel = ({data}) => {
  const [search, setSearch] = useState('');
  const [pills, setPills] = useState([
    <SearchTag.Is key={'StartupModel'} id={'StartupModel'} label={'Startup Model'}/>,
    ...data.slice(0, 1).map((v) => <SearchTag.Id key={v.id} id={v.id} label={(new URL(v.id)).hash.slice(1)}/>)
  ]);
  const canvas = useMemo(() => {
    return data.find((r) => r.id === pills.find((v) => v.props.kind === 'id').props.id);
  }, [data, pills]);

  return (
    <MantineProvider defaultColorScheme={'dark'} withNormalizeCSS>
      <Paper withBorder>
        <AppShell padding={0}>
          <AppShell.Header style={{position: 'relative', backgroundColor: 'transparent', zIndex: 'auto'}}>
            <Flex p={8}>
              <Group style={{flexGrow: 1}}>
                <PillsInput style={{flexGrow: 1}} leftSection={<IconSearch size={14}/>}>
                  <Pill.Group>
                    {pills.map((pill) => <Pill
                      key={`${pill.props.kind}:${pill.props.id}`}
                      onRemove={() => setPills((current) => {
                        return current.filter((v) => v.props.kind !== pill.props.kind || v.props.id !== pill.props.id);
                      })}
                      withRemoveButton={pill.props.withRemove}>{pill}</Pill>)}
                    <PillsInput.Field
                      // onFocus={() => combobox.openDropdown()}

                      // onBlur={() => combobox.closeDropdown()}
                      placeholder={'Search'}
                      value={search}
                      onChange={(event) => {
                        // combobox.updateSelectedOptionIndex();
                        setSearch(event.currentTarget.value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' && search.length !== 0) {
                          event.preventDefault();
                          // setPills((current) => {
                          //   if (!current.find((v) => v.type === 'text' && v.id === search)) {
                          //     return [...current, {
                          //       type: 'text',
                          //       id: search,
                          //       label: <SearchTag type={'text'} label={search}/>
                          //     }];
                          //   } else return current;
                          // });
                          // setSearch('');
                        }
                        if (event.key === 'Backspace' && search.length === 0) {
                          event.preventDefault();
                          // handleValueRemove(value[value.length - 1]);
                        }
                      }}
                    />
                  </Pill.Group>
                </PillsInput>
              </Group>
            </Flex>
            <Flex p={8}>
              <Group align={'center'} style={{flexGrow: 1, flexDirection: 'row-reverse'}}>
                <Dropdown width={180} label={'Select Model'}>
                  {data.map((r) => {
                    const label = (new URL(r.id)).hash.slice(1);
                    return <Dropdown.Option
                      key={r.id}
                      id={r.id}
                      label={label}
                      onClick={() => setPills((current) => {
                        if (!current.find((v) => v.props.kind === 'id')) {
                          return [...current, <SearchTag.Id key={r.id} id={r.id} label={label}/>];
                        } else {
                          if (!current.find((v) => v.props.kind === 'id' && v.props.id === r.id)) {
                            current.splice(current.findIndex((v) => v.props.kind === 'id'), 1, <SearchTag.Id
                              key={r.id} id={r.id} label={label}/>);
                            return [...current];
                          } else return current;
                        }
                      })}
                      rightSection={pills.find((v) => v.props.kind === 'id' && v.props.id === r.id) ?
                        <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}/>;
                  })}
                </Dropdown>
                <Dropdown width={180} label={'Model Type'}>
                  <Dropdown.Option
                    id={'StartupModel'}
                    label={'Startup Model'}
                    onClick={() => setPills((current) => {
                      if (!current.find((v) => v.props.kind === 'is')) {
                        return [...current,
                          <SearchTag.Is key={'StartupModel'} id={'StartupModel'} label={'Startup Model'}/>];
                      } else {
                        if (!current.find((v) => v.props.kind === 'is' && v.props.id === 'StartupModel')) {
                          current.splice(current.findIndex((v) => v.props.kind === 'is'), 1, <SearchTag.Is
                            key={'StartupModel'} id={'StartupModel'} label={'Startup Model'}/>);
                          return [...current];
                        } else return current;
                      }
                    })}
                    rightSection={pills.find((v) => v.props.kind === 'is' && v.props.id === 'StartupModel') ?
                      <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}/>
                  <Dropdown.Option
                    id={'BusinessModel'}
                    label={'Business Model'}
                    onClick={() => setPills((current) => {
                      if (!current.find((v) => v.props.kind === 'is')) {
                        return [...current,
                          <SearchTag.Is key={'BusinessModel'} id={'BusinessModel'} label={'Business Model'}/>];
                      } else {
                        if (!current.find((v) => v.props.kind === 'is' && v.props.id === 'BusinessModel')) {
                          current.splice(current.findIndex((v) => v.props.kind === 'is'), 1, <SearchTag.Is
                            key={'BusinessModel'} id={'BusinessModel'} label={'Business Model'}/>);
                          return [...current];
                        } else return current;
                      }
                    })}
                    rightSection={pills.find((v) => v.props.kind === 'is' && v.props.id === 'BusinessModel') ?
                      <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}/>
                </Dropdown>
              </Group>
            </Flex>
          </AppShell.Header>
          <AppShell.Main style={{minHeight: 'unset', position: 'relative', paddingTop: '56%'}}>
            <Table
              style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, height: '100%'}}
              withColumnBorders>
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td colSpan={2} rowSpan={2} css={cellWrap}>
                    <Flex
                      css={cellRoot}
                      justify="flex-start"
                      align="flex-start"
                      direction="column">
                      <Group css={cellHeader}>
                        <Title
                          order={4}
                          style={{flexGrow: 1}}>{pills.find(isStartupModel) ? 'Problems' : 'Key Partners'}</Title>
                        <ThemeIcon size={'xs'} style={{backgroundColor: 'transparent', border: 'none'}}>
                          {pills.find(isStartupModel) ? <IconAlertTriangle/> : <IconLink/>}
                        </ThemeIcon>
                      </Group>
                      <Stack mt="xs" gap={'xs'} style={{alignSelf: 'stretch', overflowY: 'auto'}}>
                        {canvas
                          .data
                          .filter((r) => (new URL(r.type)).hash.slice(1) === (pills.find(isStartupModel) ? 'Problem' : 'KeyPartner'))
                          .map((p) => {
                            return <Card
                              key={p.id}
                              padding={`${rem('0.3rem')} ${rem('0.5rem')}`}
                              style={{alignSelf: 'flex-start'}}
                              shadow="sm"
                              radius="md"
                              withBorder>
                              <Text size="sm">{p.label}</Text>
                            </Card>;
                          })}
                      </Stack>
                    </Flex>
                  </Table.Td>
                  <Table.Td colSpan={2} css={cellWrap}>
                    <Flex
                      css={cellRoot}
                      justify="flex-start"
                      align="flex-start"
                      direction="column">
                      <Group css={cellHeader}>
                        <Title
                          order={4}
                          style={{flexGrow: 1}}>{pills.find(isStartupModel) ? 'Solutions' : 'Key Activities'}</Title>
                        <ThemeIcon size={'xs'} style={{backgroundColor: 'transparent', border: 'none'}}>
                          {pills.find(isStartupModel) ? <IconBulb/> : <IconCheckbox/>}
                        </ThemeIcon>
                      </Group>
                      <Stack mt="xs" gap={'xs'} style={{alignSelf: 'stretch', overflowY: 'auto'}}>
                        {canvas
                          .data
                          .filter((r) => (new URL(r.type)).hash.slice(1) === (pills.find(isStartupModel) ? 'Solution' : 'KeyActivity'))
                          .map((p) => {
                            return <Card
                              key={p.id}
                              padding={`${rem('0.3rem')} ${rem('0.5rem')}`}
                              style={{alignSelf: 'flex-start'}}
                              shadow="sm"
                              radius="md"
                              withBorder>
                              <Text size="sm">{p.label}</Text>
                            </Card>;
                          })}
                      </Stack>
                    </Flex>
                  </Table.Td>
                  <Table.Td colSpan={2} rowSpan={2} css={cellWrap}>
                    <Flex
                      css={cellRoot}
                      justify="flex-start"
                      align="flex-start"
                      direction="column">
                      <Group css={cellHeader}>
                        <Title order={4} style={{flexGrow: 1}}>Value Propositions</Title>
                        <ThemeIcon size={'xs'} style={{backgroundColor: 'transparent', border: 'none'}}>
                          <IconGift/>
                        </ThemeIcon>
                      </Group>
                      <Stack mt="xs" gap={'xs'} style={{alignSelf: 'stretch', overflowY: 'auto'}}>
                        {canvas
                          .data
                          .filter((r) => (new URL(r.type)).hash.slice(1) === 'ValueProposition')
                          .map((p) => {
                            return <Card
                              key={p.id}
                              padding={`${rem('0.3rem')} ${rem('0.5rem')}`}
                              style={{alignSelf: 'flex-start'}}
                              shadow="sm"
                              radius="md"
                              withBorder>
                              <Text size="sm">{p.label}</Text>
                            </Card>;
                          })}
                      </Stack>
                    </Flex>
                  </Table.Td>
                  <Table.Td colSpan={2} css={cellWrap}>
                    <Flex
                      css={cellRoot}
                      justify="flex-start"
                      align="flex-start"
                      direction="column">
                      <Group css={cellHeader}>
                        <Title
                          order={4}
                          style={{flexGrow: 1}}>{pills.find(isStartupModel) ? 'Unfair Advantages' : 'Customer Relationships'}</Title>
                        <ThemeIcon size={'xs'} style={{backgroundColor: 'transparent', border: 'none'}}>
                          {pills.find(isStartupModel) ? <IconStar/> : <IconHeartHandshake/>}
                        </ThemeIcon>
                      </Group>
                      <Stack mt="xs" gap={'xs'} style={{alignSelf: 'stretch', overflowY: 'auto'}}>
                        {canvas
                          .data
                          .filter((r) => (new URL(r.type)).hash.slice(1) === (pills.find(isStartupModel) ? 'UnfairAdvantage' : 'CustomerRelationship'))
                          .map((p) => {
                            return <Card
                              key={p.id}
                              padding={`${rem('0.3rem')} ${rem('0.5rem')}`}
                              style={{alignSelf: 'flex-start'}}
                              shadow="sm"
                              radius="md"
                              withBorder>
                              <Text size="sm">{p.label}</Text>
                            </Card>;
                          })}
                      </Stack>
                    </Flex>
                  </Table.Td>
                  <Table.Td colSpan={2} rowSpan={2} css={cellWrap}>
                    <Flex
                      css={cellRoot}
                      justify="flex-start"
                      align="flex-start"
                      direction="column">
                      <Group css={cellHeader}>
                        <Title order={4} style={{flexGrow: 1}}>Customer Segments</Title>
                        <ThemeIcon size={'xs'} style={{backgroundColor: 'transparent', border: 'none'}}>
                          <IconUsers/>
                        </ThemeIcon>
                      </Group>
                      <Stack mt="xs" gap={'xs'} style={{alignSelf: 'stretch', overflowY: 'auto'}}>
                        {canvas
                          .data
                          .filter((r) => (new URL(r.type)).hash.slice(1) === 'CustomerSegment')
                          .map((p) => {
                            return <Card
                              key={p.id}
                              padding={`${rem('0.3rem')} ${rem('0.5rem')}`}
                              style={{alignSelf: 'flex-start'}}
                              shadow="sm"
                              radius="md"
                              withBorder>
                              <Text size="sm">{p.label}</Text>
                            </Card>;
                          })}
                      </Stack>
                    </Flex>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td colSpan={2} css={cellWrap}>
                    <Flex
                      css={cellRoot}
                      justify="flex-start"
                      align="flex-start"
                      direction="column">
                      <Group css={cellHeader}>
                        <Title
                          order={4}
                          style={{flexGrow: 1}}>{pills.find(isStartupModel) ? 'Key Metrics' : 'Key Resources'}</Title>
                        <ThemeIcon size={'xs'} style={{backgroundColor: 'transparent', border: 'none'}}>
                          <IconBuildingFactory2/>
                        </ThemeIcon>
                      </Group>
                      <Stack mt="xs" gap={'xs'} style={{alignSelf: 'stretch', overflowY: 'auto'}}>
                        {canvas
                          .data
                          .filter((r) => (new URL(r.type)).hash.slice(1) === (pills.find(isStartupModel) ? 'KeyMetric' : 'KeyResource'))
                          .map((p) => {
                            return <Card
                              key={p.id}
                              padding={`${rem('0.3rem')} ${rem('0.5rem')}`}
                              style={{alignSelf: 'flex-start'}}
                              shadow="sm"
                              radius="md"
                              withBorder>
                              <Text size="sm">{p.label}</Text>
                            </Card>;
                          })}
                      </Stack>
                    </Flex>
                  </Table.Td>
                  <Table.Td
                    css={cellWrap}
                    style={{borderRight: `${rem('0.0625rem')} solid var(--_table-border-color)`}}
                    colSpan={2}>
                    <Flex
                      css={cellRoot}
                      justify="flex-start"
                      align="flex-start"
                      direction="column">
                      <Group css={cellHeader}>
                        <Title order={4} style={{flexGrow: 1}}>Channels</Title>
                        <ThemeIcon size={'xs'} style={{backgroundColor: 'transparent', border: 'none'}}>
                          <IconTruck/>
                        </ThemeIcon>
                      </Group>
                      <Stack mt="xs" gap={'xs'} style={{alignSelf: 'stretch', overflowY: 'auto'}}>
                        {canvas
                          .data
                          .filter((r) => (new URL(r.type)).hash.slice(1) === 'Channel')
                          .map((p) => {
                            return <Card
                              key={p.id}
                              padding={`${rem('0.3rem')} ${rem('0.5rem')}`}
                              style={{alignSelf: 'flex-start'}}
                              shadow="sm"
                              radius="md"
                              withBorder>
                              <Text size="sm">{p.label}</Text>
                            </Card>;
                          })}
                      </Stack>
                    </Flex>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr style={{height: '25%'}}>
                  <Table.Td colSpan={5} css={cellWrap}>
                    <Flex
                      css={cellRoot}
                      justify="flex-start"
                      align="flex-start"
                      direction="column">
                      <Group css={cellHeader}>
                        <Title order={4} style={{flexGrow: 1}}>Cost Structures</Title>
                        <ThemeIcon size={'xs'} style={{backgroundColor: 'transparent', border: 'none'}}>
                          <IconTag/>
                        </ThemeIcon>
                      </Group>
                      <Stack mt="xs" gap={'xs'} style={{alignSelf: 'stretch'}}>
                        {canvas
                          .data
                          .filter((r) => (new URL(r.type)).hash.slice(1) === 'CostStructure')
                          .map((p) => {
                            return <Card
                              key={p.id}
                              padding={`${rem('0.3rem')} ${rem('0.5rem')}`}
                              style={{alignSelf: 'flex-start'}}
                              shadow="sm"
                              radius="md"
                              withBorder>
                              <Text size="sm">{p.label}</Text>
                            </Card>;
                          })}
                      </Stack>
                    </Flex>
                  </Table.Td>
                  <Table.Td colSpan={5} css={cellWrap}>
                    <Flex
                      css={cellRoot}
                      justify="flex-start"
                      align="flex-start"
                      direction="column">
                      <Group css={cellHeader}>
                        <Title order={4} style={{flexGrow: 1}}>Revenue Streams</Title>
                        <ThemeIcon size={'xs'} style={{backgroundColor: 'transparent', border: 'none'}}>
                          <IconCurrencyDollar/>
                        </ThemeIcon>
                      </Group>
                      <Stack mt="xs" gap={'xs'} style={{alignSelf: 'stretch', overflowY: 'auto'}}>
                        {canvas
                          .data
                          .filter((r) => (new URL(r.type)).hash.slice(1) === 'RevenueStream')
                          .map((p) => {
                            return <Card
                              key={p.id}
                              padding={`${rem('0.3rem')} ${rem('0.5rem')}`}
                              style={{alignSelf: 'flex-start'}}
                              shadow="sm"
                              radius="md"
                              withBorder>
                              <Text size="sm">{p.label}</Text>
                            </Card>;
                          })}
                      </Stack>
                    </Flex>
                  </Table.Td>
                </Table.Tr>
              </Table.Tbody>
            </Table>
          </AppShell.Main>
        </AppShell>
      </Paper>
    </MantineProvider>
  );
};

const isStartupModel = (v) => v.props.kind === 'is' && v.props.id === 'StartupModel';

export default BusinessModel;
