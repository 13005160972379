import React from 'react';
import {Anchor, AppShell, Flex, Group, MantineProvider, Paper, Table, Text, TextInput, Tooltip} from '@mantine/core';
import {IconSearch,} from '@tabler/icons-react';

const Manifest = ({data}) => {
  return (
    <MantineProvider defaultColorScheme={'dark'} withNormalizeCSS>
      <Paper withBorder>
        <AppShell padding={0}>
          <AppShell.Header style={{position: 'relative', backgroundColor: 'transparent', zIndex: 'auto'}}>
            <Flex style={{padding: 8}}>
              <Group>
                <TextInput placeholder="Search" icon={<IconSearch/>}/>
              </Group>
            </Flex>
          </AppShell.Header>
          <AppShell.Main style={{minHeight: 'unset'}}>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Type</Table.Th>
                  <Table.Th>Label</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{data.map((row) => {
                return <Table.Tr key={row.id}>
                  <Table.Td>{row.type}</Table.Td>
                  <Table.Td>
                    <Flex gap={0} justify={'flex-start'} align={'flex-start'} direction={'column'}>
                      <Tooltip label={'https://includedicubuway.tz'} position={'top-start'}>
                        <Anchor href={row.url} target={'_blank'} underline={'never'}>{row.label}</Anchor>
                      </Tooltip>
                      <Text size={'0.7rem'} c={'dimmed'}>{row.id}</Text>
                    </Flex>
                  </Table.Td>
                </Table.Tr>;
              })}</Table.Tbody>
            </Table>
          </AppShell.Main>
        </AppShell>
      </Paper>
    </MantineProvider>
  );
};

export default Manifest;
