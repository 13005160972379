import React, {Children, useState} from 'react';
import {Input, Popover, Table} from '@mantine/core';
import {IconChevronDown, IconChevronUp} from '@tabler/icons-react';

const Dropdown = ({label, width, children}) => {
  const [opened, setOpened] = useState(false);

  // const result = Children.toArray(children).filter((c) => c.type.displayName === '@mdx@html/DropdownOption');
  // console.log(result);

  return <Popover
    width={width}
    opened={opened}
    onChange={setOpened}
    offset={-5}
    styles={{dropdown: {padding: 0}}}
    position={'bottom-end'}>
    <Popover.Target>
      <Input
        component={'button'}
        variant={'unstyled'}
        css={{
          button: {
            color: 'var(--mantine-color-dark-1)',
            lineHeight: 2.6
          },
          '&:hover': {
            button: {color: 'var(--mantine-color-white)'},
            svg: {stroke: 'var(--mantine-color-white)'}
          }
        }}
        onClick={() => setOpened((o) => !o)}
        rightSection={
          opened ?
            <IconChevronUp color={'var(--mantine-color-dark-1)'} size={14}/> :
            <IconChevronDown color={'var(--mantine-color-dark-1)'} size={14}/>
        }
        pointer>{label}</Input>
    </Popover.Target>
    <Popover.Dropdown>
      <Table highlightOnHover>
        <Table.Tbody>
          {Children.toArray(children)
            .filter((c) => c.type.displayName === '@mdx@html/DropdownOption')}
          {/*<Table.Tr>*/}
          {/*  <Table.Td>*/}
          {/*    <Input*/}
          {/*      component={'button'}*/}
          {/*      variant={'unstyled'}*/}
          {/*      onClick={() => setPills((current) => {*/}
          {/*        if (!current.find((v) => v.type === 'is' && v.id === 'Opened')) {*/}
          {/*          return [...current, {*/}
          {/*            type: 'is',*/}
          {/*            id: 'Opened',*/}
          {/*            label: <SearchTag type={'is'} label={'Opened'}/>*/}
          {/*          }];*/}
          {/*        } else return current.filter((v) => v.type !== 'is' || v.id !== 'Opened');*/}
          {/*      })}*/}
          {/*      leftSection={<IconCircle size={14} style={{color: 'var(--color-green-badge)'}}/>}*/}
          {/*      rightSection={pills.find((v) => v.type === 'is' && v.id === 'Opened') ?*/}
          {/*        <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}*/}
          {/*      size={'xs'}*/}
          {/*      pointer>Opened</Input>*/}
          {/*  </Table.Td>*/}
          {/*</Table.Tr>*/}
          {/*<Table.Tr>*/}
          {/*  <Table.Td>*/}
          {/*    <Input*/}
          {/*      component={'button'}*/}
          {/*      variant={'unstyled'}*/}
          {/*      onClick={() => setPills((current) => {*/}
          {/*        if (!current.find((v) => v.type === 'is' && v.id === 'Completed')) {*/}
          {/*          return [...current, {*/}
          {/*            type: 'is',*/}
          {/*            id: 'Completed',*/}
          {/*            label: <SearchTag type={'is'} label={'Completed'}/>*/}
          {/*          }];*/}
          {/*        } else return current.filter((v) => v.type !== 'is' || v.id !== 'Completed');*/}
          {/*      })}*/}
          {/*      leftSection={<IconCircleCheck size={14} style={{color: 'var(--color-blue)'}}/>}*/}
          {/*      rightSection={pills.find((v) => v.type === 'is' && v.id === 'Completed') ?*/}
          {/*        <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}*/}
          {/*      size={'xs'}*/}
          {/*      pointer>Completed</Input>*/}
          {/*  </Table.Td>*/}
          {/*</Table.Tr>*/}
          {/*<Table.Tr>*/}
          {/*  <Table.Td>*/}
          {/*    <Input*/}
          {/*      component={'button'}*/}
          {/*      variant={'unstyled'}*/}
          {/*      onClick={() => setPills((current) => {*/}
          {/*        if (!current.find((v) => v.type === 'is' && v.id === 'Cancelled')) {*/}
          {/*          return [...current, {*/}
          {/*            type: 'is',*/}
          {/*            id: 'Cancelled',*/}
          {/*            label: <SearchTag type={'is'} label={'Cancelled'}/>*/}
          {/*          }];*/}
          {/*        } else return current.filter((v) => v.type !== 'is' || v.id !== 'Cancelled');*/}
          {/*      })}*/}
          {/*      leftSection={<IconCircleOff size={14} style={{color: 'var(--mantine-color-dark-4)'}}/>}*/}
          {/*      rightSection={pills.find((v) => v.type === 'is' && v.id === 'Cancelled') ?*/}
          {/*        <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}*/}
          {/*      size={'xs'}*/}
          {/*      pointer>Cancelled</Input>*/}
          {/*  </Table.Td>*/}
          {/*</Table.Tr>*/}
          {/*<Table.Tr>*/}
          {/*  <Table.Td>*/}
          {/*    <Input*/}
          {/*      component={'button'}*/}
          {/*      variant={'unstyled'}*/}
          {/*      onClick={() => setPills((current) => {*/}
          {/*        if (!current.find((v) => v.type === 'is' && v.id === 'NeedsAction')) {*/}
          {/*          return [...current, {*/}
          {/*            type: 'is',*/}
          {/*            id: 'NeedsAction',*/}
          {/*            label: <SearchTag type={'is'} label={'Needs Action'}/>*/}
          {/*          }];*/}
          {/*        } else return current.filter((v) => v.type !== 'is' || v.id !== 'NeedsAction');*/}
          {/*      })}*/}
          {/*      leftSection={<IconAlertCircle size={14} style={{color: 'var(--color-error-border)'}}/>}*/}
          {/*      rightSection={pills.find((v) => v.type === 'is' && v.id === 'NeedsAction') ?*/}
          {/*        <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}*/}
          {/*      size={'xs'}*/}
          {/*      pointer>Needs Action</Input>*/}
          {/*  </Table.Td>*/}
          {/*</Table.Tr>*/}
          {/*<Table.Tr>*/}
          {/*  <Table.Td>*/}
          {/*    <Input*/}
          {/*      component={'button'}*/}
          {/*      variant={'unstyled'}*/}
          {/*      onClick={() => setPills((current) => {*/}
          {/*        if (!current.find((v) => v.type === 'is' && v.id === 'InProcess')) {*/}
          {/*          return [...current, {*/}
          {/*            type: 'is',*/}
          {/*            id: 'InProcess',*/}
          {/*            label: <SearchTag type={'is'} label={'In Process'}/>*/}
          {/*          }];*/}
          {/*        } else return current.filter((v) => v.type !== 'is' || v.id !== 'InProcess');*/}
          {/*      })}*/}
          {/*      leftSection={<IconProgress size={14} style={{color: 'var(--color-warning-text)'}}/>}*/}
          {/*      rightSection={pills.find((v) => v.type === 'is' && v.id === 'InProcess') ?*/}
          {/*        <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}*/}
          {/*      size={'xs'}*/}
          {/*      pointer>In Process</Input>*/}
          {/*  </Table.Td>*/}
          {/*</Table.Tr>*/}
        </Table.Tbody>
      </Table>
    </Popover.Dropdown>
  </Popover>;
};
Dropdown.displayName = '@mdx@html/Dropdown';

const DropdownOption = ({id, label, onClick, leftSection, rightSection}) => {

  return <Table.Tr key={id}>
    <Table.Td>
      <Input
        component={'button'}
        variant={'unstyled'}
        onClick={onClick}
        leftSection={leftSection}
        rightSection={rightSection}
        // onClick={() => setPills((current) => {
        //   if (!current.find((v) => v.type === 'is' && v.id === 'Opened')) {
        //     return [...current, {
        //       type: 'is',
        //       id: 'Opened',
        //       label: <SearchTag type={'is'} label={'Opened'}/>
        //     }];
        //   } else return current.filter((v) => v.type !== 'is' || v.id !== 'Opened');
        // })}
        // leftSection={<IconCircle size={14} style={{color: 'var(--color-green-badge)'}}/>}
        // rightSection={pills.find((v) => v.type === 'is' && v.id === 'Opened') ?
        //   <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}
        size={'xs'}
        pointer>{label}</Input>
    </Table.Td>
  </Table.Tr>;
};
DropdownOption.displayName = '@mdx@html/DropdownOption';

Dropdown.Option = DropdownOption;
export default Dropdown;
