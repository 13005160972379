import React, {useState} from 'react';
import {
  ActionIcon,
  AppShell,
  Badge,
  Flex,
  Group,
  Highlight,
  MantineProvider,
  Paper,
  Pill,
  PillsInput,
  Table,
  Text,
  ThemeIcon
} from '@mantine/core';
import {
  IconAlertCircle,
  IconCheck,
  IconCircle,
  IconCircleCheck,
  IconCircleOff,
  IconMessage,
  IconProgress,
  IconSearch,
} from '@tabler/icons-react';
import {Dropdown, SearchTag, StatusIcon} from '../../components';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(LocalizedFormat);

const Issues = ({data}) => {
  const [search, setSearch] = useState('');
  const [pills, setPills] = useState([
    <SearchTag.Is key={'Opened'} id={'Opened'} label={'Opened'} withRemove/>,
    <SearchTag.Is key={'InProcess'} id={'InProcess'} label={'In Process'} withRemove/>
  ]);

  return (
    <MantineProvider defaultColorScheme={'dark'} withNormalizeCSS>
      <Paper withBorder>
        <AppShell padding={0}>
          <AppShell.Header style={{position: 'relative', backgroundColor: 'transparent', zIndex: 'auto'}}>
            <Flex p={8}>
              <Group style={{flexGrow: 1}}>
                <PillsInput style={{flexGrow: 1}} leftSection={<IconSearch size={14}/>}>
                  <Pill.Group>
                    {pills.map((pill) => <Pill
                      key={`${pill.props.kind}:${pill.props.id}`}
                      onRemove={() => setPills((current) => {
                        return current.filter((v) => v.props.kind !== pill.props.kind || v.props.id !== pill.props.id);
                      })}
                      withRemoveButton={pill.props.withRemove}>{pill}</Pill>)}
                    <PillsInput.Field
                      // onFocus={() => combobox.openDropdown()}
                      // onBlur={() => combobox.closeDropdown()}
                      placeholder={'Search'}
                      value={search}
                      onChange={(event) => {
                        // combobox.updateSelectedOptionIndex();
                        setSearch(event.currentTarget.value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' && search.length !== 0) {
                          event.preventDefault();
                          setPills((current) => {
                            if (!current.find((v) => v.props.kind === 'text' && v.props.id === search)) {
                              return [...current, <SearchTag.Text key={search} id={search} label={search} withRemove/>];
                            } else return current;
                          });
                          setSearch('');
                        }
                        if (event.key === 'Backspace' && search.length === 0) {
                          event.preventDefault();
                          // handleValueRemove(value[value.length - 1]);
                        }
                      }}
                    />
                  </Pill.Group>
                </PillsInput>
              </Group>
            </Flex>
            <Flex p={8}>
              <Group align={'center'} style={{flexGrow: 1, flexDirection: 'row-reverse'}}>
                <Dropdown width={180} label={'Status'}>
                  <Dropdown.Option
                    id={'Opened'}
                    label={'Opened'}
                    onClick={() => setPills((current) => {
                      if (!current.find((v) => v.props.kind === 'is' && v.props.id === 'Opened')) {
                        return [...current, <SearchTag.Is key={'Opened'} id={'Opened'} label={'Opened'} withRemove/>];
                      } else return current.filter((v) => v.props.kind !== 'is' || v.props.id !== 'Opened');
                    })}
                    leftSection={<IconCircle size={14} style={{color: 'var(--color-green-badge)'}}/>}
                    rightSection={pills.find((v) => v.props.kind === 'is' && v.props.id === 'Opened') ?
                      <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}/>
                  <Dropdown.Option
                    id={'Completed'}
                    label={'Completed'}
                    onClick={() => setPills((current) => {
                      if (!current.find((v) => v.props.kind === 'is' && v.props.id === 'Completed')) {
                        return [...current,
                          <SearchTag.Is key={'Completed'} id={'Completed'} label={'Completed'} withRemove/>];
                      } else return current.filter((v) => v.props.kind !== 'is' || v.props.id !== 'Completed');
                    })}
                    leftSection={<IconCircleCheck size={14} style={{color: 'var(--color-blue)'}}/>}
                    rightSection={pills.find((v) => v.props.kind === 'is' && v.props.id === 'Completed') ?
                      <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}/>
                  <Dropdown.Option
                    id={'Cancelled'}
                    label={'Cancelled'}
                    onClick={() => setPills((current) => {
                      if (!current.find((v) => v.props.kind === 'is' && v.props.id === 'Cancelled')) {
                        return [...current,
                          <SearchTag.Is key={'Cancelled'} id={'Cancelled'} label={'Cancelled'} withRemove/>];
                      } else return current.filter((v) => v.props.kind !== 'is' || v.props.id !== 'Cancelled');
                    })}
                    leftSection={<IconCircleOff size={14} style={{color: 'var(--mantine-color-dark-4)'}}/>}
                    rightSection={pills.find((v) => v.props.kind === 'is' && v.props.id === 'Cancelled') ?
                      <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}/>
                  <Dropdown.Option
                    id={'NeedsAction'}
                    label={'Needs Action'}
                    onClick={() => setPills((current) => {
                      if (!current.find((v) => v.props.kind === 'is' && v.props.id === 'NeedsAction')) {
                        return [...current,
                          <SearchTag.Is key={'NeedsAction'} id={'NeedsAction'} label={'Needs Action'} withRemove/>];
                      } else return current.filter((v) => v.props.kind !== 'is' || v.props.id !== 'NeedsAction');
                    })}
                    leftSection={<IconAlertCircle size={14} style={{color: 'var(--color-error-border)'}}/>}
                    rightSection={pills.find((v) => v.props.kind === 'is' && v.props.id === 'NeedsAction') ?
                      <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}/>
                  <Dropdown.Option
                    id={'InProcess'}
                    label={'In Process'}
                    onClick={() => setPills((current) => {
                      if (!current.find((v) => v.props.kind === 'is' && v.props.id === 'InProcess')) {
                        return [...current,
                          <SearchTag.Is key={'InProcess'} id={'InProcess'} label={'In Process'} withRemove/>];
                      } else return current.filter((v) => v.props.kind !== 'is' || v.props.id !== 'InProcess');
                    })}
                    leftSection={<IconProgress size={14} style={{color: 'var(--color-warning-text)'}}/>}
                    rightSection={pills.find((v) => v.props.kind === 'is' && v.props.id === 'InProcess') ?
                      <IconCheck color={'var(--mantine-color-blue-6)'} size={14}/> : null}/>
                </Dropdown>
              </Group>
            </Flex>
          </AppShell.Header>
          <AppShell.Main style={{minHeight: 'unset'}}>
            <Table highlightOnHover>
              <Table.Tbody>{data
                .sort((lhs, rhs) => new Date(rhs.createDate ?? null) - new Date(lhs.createDate ?? null))
                .filter((issue) => pills.find((v) => v.props.kind === 'is' && v.props.id === (issue.status ?? 'Opened')))
                .filter((issue) => {
                  if (pills.reduce((count, v) => count + (v.props.kind === 'text'), 0) > 0) {
                    return pills.some((v) => v.props.kind === 'text' && issue.label.includes(v.props.id)) || (search.length > 0 && issue.label.includes(search));
                  } else return true;
                })
                .map((issue) => {
                  return <Table.Tr key={issue.id}>
                    <Table.Td style={{verticalAlign: 'top'}}>
                      <Group>
                        <ThemeIcon size="xs" style={{backgroundColor: 'transparent', border: 'none'}}>
                          <StatusIcon status={issue.status}/>
                        </ThemeIcon>
                      </Group>
                    </Table.Td>
                    <Table.Td>
                      <Flex gap={0} justify={'flex-start'} align={'flex-start'} direction={'column'}>
                        <Group>
                          <Highlight
                            size={'1.1rem'}
                            fw={500}
                            highlight={[
                              ...pills.filter((v) => v.props.kind === 'text').map((v) => v.props.id),
                              search
                            ]}>{issue.label}</Highlight>
                          <Group gap="xs">
                            {issue.related.map((r) => {
                              return <Badge
                                key={r.id}
                                variant={'light'}
                                color={r.color ?? 'blue'}
                                size={'sm'}
                                radius={'sm'}>{r.label ?? (new URL(r.id)).hash.slice(1)}</Badge>;
                            })}
                          </Group>
                        </Group>
                        <Text
                          size={'0.8rem'} c={'dimmed'}
                          mt={'0.3rem'}>{`Opened on ${dayjs(issue.createDate).format('ll')}`}</Text>
                      </Flex>
                    </Table.Td>
                    <Table.Td style={{verticalAlign: 'top'}}>
                      <Group>
                        <ActionIcon size={'xs'} style={{backgroundColor: 'transparent', border: 'none'}}>
                          <IconMessage/>
                        </ActionIcon>
                      </Group>
                    </Table.Td>
                  </Table.Tr>;
                })}</Table.Tbody>
            </Table>
          </AppShell.Main>
        </AppShell>
      </Paper>
    </MantineProvider>
  );
};

export default Issues;
