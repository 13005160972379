import React, {useMemo, useState} from 'react';
import {AppShell, Flex, Group, MantineProvider, Paper, Pill, PillsInput} from '@mantine/core';
import {IconSearch,} from '@tabler/icons-react';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import {Chart} from 'react-chartjs-2';
import {RRule} from 'rrule';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';

dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(utc);

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const options = {
  plugins: {
    title: {
      display: true,
      text: 'Chart.js Bar Chart - Stacked',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const Estimator = ({dtstart, until, data}) => {
  const [search, setSearch] = useState('');
  const [pills, setPills] = useState([]);

  const rule = new RRule({
    freq: RRule.DAILY,
    dtstart: new Date(dtstart),
    until: new Date(until),
    interval: 1
  });

  let [b0, b1] = useMemo(() => {
    let [sX, sY, sXY, sXX, n] = rule.all()
      .map((dt, i) => {
        return [i + 1, data.reduce((count, issue) => {
          if (issue.endDate && issue.status === 'Completed') {
            count += dayjs(dt)
              .utc(true)
              .hour(0)
              .minute(0)
              .second(0)
              .isSameOrAfter(
                dayjs(issue.endDate)
                  .utc(true)
                  .hour(0)
                  .minute(0)
                  .second(0)
              ) ? 1 : 0;
          }
          return count;
        }, 0)];
      })
      .reduce(([sX, sY, sXY, sXX, n], [x, y]) => {
        return [sX + x, sY + y, sXY + (x * y), sXX + Math.pow(x, 2), n + 1];
      }, [0, 0, 0, 0, 0]);
    const b1 = (n * sXY - sX * sY) / (n * sXX - Math.pow(sX, 2));
    const b0 = (sY / n) - (b1 * sX) / n;
    return [b0, b1];
  }, [rule]);

  let [d0, d1] = useMemo(() => {
    let [sX, sY, sXY, sXX, n] = rule.all()
      .map((dt, i) => {
        return [i + 1, data.reduce((count, issue) => {
          if (!issue.endDate && ['Opened', 'InProcess', 'NeedsAction'].includes((issue.status ?? 'Opened'))) {
            count += dayjs(dt)
              .utc(true)
              .hour(0)
              .minute(0)
              .second(0)
              .isSameOrAfter(
                dayjs(issue.createDate)
                  .utc(true)
                  .hour(0)
                  .minute(0)
                  .second(0)
              ) ? 1 : 0;
          }
          return count;
        }, 0)];
      })
      .reduce(([sX, sY, sXY, sXX, n], [x, y]) => {
        return [sX + x, sY + y, sXY + (x * y), sXX + Math.pow(x, 2), n + 1];
      }, [0, 0, 0, 0, 0]);
    const b1 = (n * sXY - sX * sY) / (n * sXX - Math.pow(sX, 2));
    const b0 = (sY / n) - (b1 * sX) / n;
    return [b0, b1];
  }, [rule]);

  const labels = rule.all().map((dt) => `${dt.getMonth() + 1}/${dt.getDate()}`);
  const datasets = [
    {
      type: 'line',
      label: 'Linear (Done)',
      borderColor: 'rgb(255,99,97)',
      borderWidth: 1,
      fill: false,
      data: rule.all().map((_, i) => (b1 * (i + 1)) + b0),
    },
    {
      type: 'line',
      label: 'Linear (Remaining)',
      borderColor: 'rgb(255,166,0)',
      borderWidth: 1,
      fill: false,
      data: rule.all().map((_, i) => (d1 * (i + 1)) + d0),
    },
    {
      type: 'bar',
      label: 'Done',
      backgroundColor: 'rgb(88,80,141)',
      data: rule.all().map((dt) => {
        return data.reduce((count, issue) => {
          if (issue.endDate && issue.status === 'Completed') {
            count += dayjs(dt)
              .utc(true)
              .hour(0)
              .minute(0)
              .second(0)
              .isSameOrAfter(
                dayjs(issue.endDate)
                  .utc(true)
                  .hour(0)
                  .minute(0)
                  .second(0)
              ) ? 1 : 0;
          }
          return count;
        }, 0);
      }),
    },
    {
      type: 'bar',
      label: 'Remaining',
      backgroundColor: 'rgb(0,63,92)',
      data: rule.all().map((dt) => {
        return data.reduce((count, issue) => {
          if (!issue.endDate && ['Opened', 'InProcess', 'NeedsAction'].includes((issue.status ?? 'Opened'))) {
            count += dayjs(dt)
              .utc(true)
              .hour(0)
              .minute(0)
              .second(0)
              .isSameOrAfter(
                dayjs(issue.createDate)
                  .utc(true)
                  .hour(0)
                  .minute(0)
                  .second(0)
              ) ? 1 : 0;
          }
          return count;
        }, 0);
      }),
    },
  ];

  return (
    <MantineProvider defaultColorScheme={'dark'} withNormalizeCSS>
      <Paper withBorder>
        <AppShell padding={0}>
          <AppShell.Header style={{position: 'relative', backgroundColor: 'transparent', zIndex: 'auto'}}>
            <Flex p={8}>
              <Group style={{flexGrow: 1}}>
                <PillsInput style={{flexGrow: 1}} leftSection={<IconSearch size={14}/>}>
                  <Pill.Group>
                    {pills.map((pill) => <Pill
                      key={`${pill.props.kind}:${pill.props.id}`}
                      onRemove={() => setPills((current) => {
                        return current.filter((v) => v.props.kind !== pill.props.kind || v.props.id !== pill.props.id);
                      })}
                      withRemoveButton={pill.props.withRemove}>{pill}</Pill>)}
                    <PillsInput.Field
                      // onFocus={() => combobox.openDropdown()}

                      // onBlur={() => combobox.closeDropdown()}
                      placeholder={'Search'}
                      value={search}
                      onChange={(event) => {
                        // combobox.updateSelectedOptionIndex();
                        setSearch(event.currentTarget.value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' && search.length !== 0) {
                          event.preventDefault();
                          // setPills((current) => {
                          //   if (!current.find((v) => v.type === 'text' && v.id === search)) {
                          //     return [...current, {
                          //       type: 'text',
                          //       id: search,
                          //       label: <SearchTag type={'text'} label={search}/>
                          //     }];
                          //   } else return current;
                          // });
                          // setSearch('');
                        }
                        if (event.key === 'Backspace' && search.length === 0) {
                          event.preventDefault();
                          // handleValueRemove(value[value.length - 1]);
                        }
                      }}
                    />
                  </Pill.Group>
                </PillsInput>
              </Group>
            </Flex>
            <Flex p={8}>
              <Group align={'center'} style={{flexGrow: 1, flexDirection: 'row-reverse'}}>
              </Group>
            </Flex>
          </AppShell.Header>
          <AppShell.Main style={{minHeight: 'unset'}}>
            <Chart type="bar" options={options} data={{labels, datasets}}/>
          </AppShell.Main>
        </AppShell>
      </Paper>
    </MantineProvider>
  );
};

export default Estimator;
