import React from 'react';
import {IconAlertCircle, IconProgress, IconCircleCheck, IconCircleOff, IconCircle} from '@tabler/icons-react';

const StatusIcon = ({status}) => {
  switch (status) {
  case 'Cancelled':
    return <IconCircleOff style={{color: 'var(--mantine-color-dark-4)'}}/>;
  case 'NeedsAction':
    return <IconAlertCircle style={{color: 'var(--color-error-border)'}}/>;
  case 'Completed':
    return <IconCircleCheck style={{color: 'var(--color-blue)'}}/>;
  case 'InProcess':
    return <IconProgress style={{color: 'var(--color-warning-text)'}}/>;
  default:
    return <IconCircle style={{color: 'var(--color-green-badge)'}}/>;
  }
};
StatusIcon.displayName = '@mdx@html/StatusIcon';

export default StatusIcon;
